<template>
  <div class="courier-cash-register-listing">
    <title-bar title="Cash register" />

    <div class="cash-register-current-state">
      <span>Current state</span>
      <span>{{ cash | price }}</span>
    </div>

    <b-button
      :disabled="cash === 0"
      @click="showModal = true"
      variant="success"
      class="mt-2 mb-4"
      >Hand over the cash register</b-button>

    <b-modal v-model="showModal" dialog-class="no-header">
      <template #modal-header>
        <div @click="showModal = false" class="modal-close">
          <img src="@/assets/images/icons/xmark.svg" alt="" />
        </div>
      </template>

      Hand over the cash register?

      <template #modal-footer>
        <div @click="confirm">Confirm</div>
      </template>
    </b-modal>

    <div class="cash-register-listing">
      <div class="cash-register-listing-title">Cash register history</div>
      <div
        v-for="(record, index) of records"
        :key="index"
        class="cash-register-listing-line"
      >
        <div class="cash-register-listing-id">
          <span v-if="record.cashRegistryType === 'CREDIT'" class="credit">{{
            $helper.getEnumTranslation('cash_registry_type', 'CREDIT')
          }}</span>
          <span
            v-else-if="record.cashRegistryType === 'WITHDRAW'"
            class="withdraw"
            >{{
              $helper.getEnumTranslation('cash_registry_type', 'WITHDRAW')
            }}</span
          >
          <span v-else>#{{ getOrderNumber(record.order) }}</span>
        </div>
        <div class="cash-register-listing-date">
          {{ record.createdAt | moment('DD.MM.YYYY HH:ss') }}
        </div>
        <div class="cash-register-listing-amount">
          {{ record.amount | price }}
        </div>
      </div>

      <b-pagination
        class="custom-table-pagination"
        pills
        v-model="currentPage"
        :total-rows="pages"
        :per-page="perPage"
        @input="paginationChange"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      cash: 0,
      currentPage: 1,
      pages: null,
      perPage: 10,
      records: null,
      showModal: false,
    }
  },
  created() {
    this.calcCash()
    this.loadRecords()
  },
  methods: {
    calcCash() {
      this.cash = 0
      // eslint-disable-next-line prefer-template
      const url = '/cash_registries/courier/' + this.userData.id + '/balance'
      this.$CashRegistries.getResourceByUrl({ url }).then(response => {
        if (response.data) {
          this.cash = response.data.balance
        }
      })
    },
    loadRecords() {
      const params = {
        courier: this.userData['@id'],
        itemsPerPage: this.perPage,
        page: this.currentPage,
        'order[createdAt]': 'desc',
      }
      this.$CashRegistries
        .getCollection({ params }, 'cash_registry_list')
        .then(response => {
          this.records = response.data['hydra:member']
          this.pages = response.data['hydra:totalItems']
        })
    },
    confirm() {
      const body = {
        courier: this.userData['@id'],
        amount: this.cash.toString(),
        cashRegistryType: 'WITHDRAW',
      }
      this.$CashRegistries
        .createResource({ body })
        .then(response => {
          if (response.status === 201) {
            this.$helper.showToast(this.$toast, Toast, 'Withdraw completed', '', 'success')
            this.showModal = false
            this.cash = 0
            this.loadRecords()
          }
        })
        .catch(() => {
          this.$helper.showToast(this.$toast, Toast, 'Failed to withdraw', '', 'danger')
        })
    },
    paginationChange() {
      this.$router.push({
        query: { ...this.$route.query, page: this.currentPage },
      })
      this.loadRecords()
    },
    getOrderNumber(iri) {
      const arr = iri.split('/')
      return arr[arr.length - 1]
    },
  },
}
</script>
